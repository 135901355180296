import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from "../page/Index";
import axios from 'axios'
import store from '../store/index'
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/yuyueIndex',
    name: 'yuyueIndex',
    component: () => import("../page/yuyueIndex"),
    meta:{
      keepAlive: true
    }
  },
  {
    path: '/addVisitor',
    name: 'addVisitor',
    component: () => import("../page/addVisitor")
    
  },
  {
    path: '/historyDetail',
    name: 'historyDetail',
    component: () => import('../page/historyDetail'),
    meta: {
      requireAuth: true
    },
  },
  {
    path: '/Electronic',
    name: 'Electronic',
    component: () => import('../page/Electronic')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../page/Login')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../page/success')
  },
  {
    path: '/museumList',
    name: 'museumList',
    component: () => import('../page/museumList')
  }
];


const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
 
  if (to.meta.requireAuth) { 
    console.log('1') // 判断该路由是否需要登录权限
      if (localStorage.getItem('signature')) {  // 通过缓存获取当前的token是否存在
          next();
      }
      else {
        
          next({
              path: '/Login',
              query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
          })
      }
  }
  else {
      next();
      
  }
})
export default router
