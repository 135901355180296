import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    signature: '',
    perSon:[]
  },
  mutations: {
    login(state,name){
      state.signature = name
    },
    ediot(state, data) {
      state.perSon[data.index] = { ...state.perSon[data.index], ...data.data };
      state.perSon = [...state.perSon]
    },
    ADDPER(state, data) {
      state.perSon.unshift(data);
    },
    DELETEPER(state, data) {
      state.perSon.splice(data, 1)
    },
    CHECKED(state,data) {
      state.perSon[data.index].isSelected = data.checked
    }
  },
  getters: {
    selected(state) {
      return state.perSon.filter(item => item.isSelected)
    },
    getPer(state) {
      return (index) => state.perSon[index]
    }
  },
  actions: {
  },
  modules: {
  }
})
